export const API_URL = 'https://dev.em.techfunder.de';
export const BRN_URL = 'https://api.tapcat.fun';
// export const BOT_ID = 'KY24L16X12'; //realty
export const BOT_ID = 'EDRDEZSGXH';
export const MERCHANT_ID = '6UZG2TW3DQ';
export const TASK_ID = '66cf675fd4f11bee58360e3e';

export const SlEEP_TIME = 0.3 * 60 * 1000;
export const ACTIVE_TIME = 0.6 * 60 * 1000;


export const APP_VERSION = '1.0.1';

export const TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnRpdHkiOiJhY2NvdW50IiwiYWNjb3VudF9pZCI6IkVZMEU3VFlCQlpKSFM3TCIsImlhdCI6MTczNzExOTA4NH0.BeOZWpQgmON3H8S8YIUsGZ23s4hxMq8Dmge1Zf-9TZw'
