import {ACTIVE_TIME} from "../../../logic/server/Variables";

const ProgressBar = ({time}) => {
    const onePxValue = ACTIVE_TIME / 250;
    const heightPx =  Math.floor(time / onePxValue);

    return(
        heightPx ? (
            <div  className={'progress-bar--container'}>
              <div className={'progress-bar'}>
              <div className={'progress-bar--active'} style={{height: `${heightPx}px`}}></div>
              </div>
            </div>) : (<div></div>)
    )
}
export default ProgressBar;
