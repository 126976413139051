import React, {useState} from "react";
// import play from "../../../assets/play.svg";
// import friends from "../../../assets/friends.svg";
// import tasks from "../../../assets/tasks.svg";
// import buyCoins from "../../../assets/buy-coins.svg";
import {ReactComponent as PlayIcon} from '../../../assets/game-page.svg';
import {ReactComponent as TasksIcon} from '../../../assets/tasks-page.svg';
import {ReactComponent as FriendsIcon} from '../../../assets/friends-page.svg';
import {ReactComponent as BuyCoinsIcon} from '../../../assets/buy-coins.svg';
import {ReactComponent as RedeemIcon} from '../../../assets/redeem-page.svg';
import {ReactComponent as BagIcon} from '../../../assets/bag-icon.svg';
import {ReactComponent as CommunityIcon} from '../../../assets/community.svg';
import {
    PATH_CLAIM,
    PATH_COLLECTIBLES,
    PATH_Community,
    PATH_EARNED,
    PATH_MAIN,
    PATH_REDEEM,
    PATH_TASKS
} from "../../../constants/Paths";
import {useNavigate} from "react-router-dom";
import ImpactOccurredHapticFeedback from "../haptic/ImpactOccurredHapticFeedback";
import {useTelegram} from "../../../hooks/useTelegram";

const FooterFunction = ({currentPage}) => {
    const {webApp, executeMethod} = useTelegram();
    // console.log(webApp?.HapticFeedback);

    const navigate = useNavigate();
    const onImpact = () => {
        try {
            webApp?.HapticFeedback?.impactOccurred('medium');
        } catch (err) {
            console.log('no impactOccurred')
        }
    }
    const onNotify = () => {
        try {
            webApp?.HapticFeedback?.notificationOccurred('success');
        } catch (err) {
            console.log('no notificationOccurred')
        }
    }

    return (
        <div style={{zIndex: 1000}}>
            <div className='footer-container'>

                <div className={`footer__cards ${currentPage === 'main' ? 'footer__cards--active' : ''}`}
                     onClick={() => {navigate(PATH_MAIN);onImpact();}}>
                    <PlayIcon className={'footer__icon'}/>
                    <div className={`forms__bottom-results-container forms__bottom-icon--day-mode`}>
                        {/*<div className='footer__text text-regular'>Play</div>*/}
                    </div>
                </div>

                <div className={`footer__cards ${currentPage === 'tasks' ? 'footer__cards--active' : ''} `}
                     onClick={() => {navigate(PATH_TASKS); onNotify();}}>
                    <TasksIcon  className={'footer__icon'}/>
                    <div className='forms__bottom-results-container'>
                        {/*<div className='footer__text text-regular'>Tasks</div>*/}
                    </div>
                </div>
                <div className={`footer__cards ${currentPage === 'collectibles' ? 'footer__cards--active' : ''}  `}
                     onClick={() => navigate(PATH_COLLECTIBLES)}>
                    <BagIcon  className={'footer__icon'}/>
                    <div className='forms__bottom-results-container'>
                        {/*<div className='footer__text text-regular'>Tasks</div>*/}
                    </div>
                </div>
                
                
                <div className={`footer__cards ${currentPage === 'earnings' ? 'footer__cards--active' : ''} `}
                     onClick={() => navigate(PATH_EARNED)}>
                    <FriendsIcon className={'footer__icon'}/>
                    <div className='forms__bottom-results-container'>
                        {/*<div className='footer__text text-regular'>Friends</div>*/}
                    </div>
                </div>
                <div className={`footer__cards ${currentPage === 'community' ? 'footer__cards--active' : ''} `}
                     onClick={() => navigate(PATH_Community)}>
                    {/*<CommunityIcon className={'footer__icon'}/>*/}
                    <CommunityIcon className={'footer__icon'}/>
                    <div className='forms__bottom-results-container'>
                        {/*<div className='footer__text text-regular'>Friends</div>*/}
                    </div>
                </div>
                <div className={`footer__cards ${currentPage === 'redeem' ? 'footer__cards--active' : ''} `}
                     onClick={() => navigate(PATH_CLAIM)}>
                    <RedeemIcon className={'footer__icon'}/>
                    <div className='forms__bottom-results-container'>
                        {/*<div className='footer__text text-regular'>Redeem</div>*/}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FooterFunction;
