import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
import FooterFunction from "../../components/app/functions/FooterFunction";
import React, {useEffect, useState} from "react";
import {
    getCustomersWallet,
} from "../../logic/server/api";
import ProfilePic from '../../assets/coin-bg.png';
import {PATH_Community, PATH_PROFILE} from "../../constants/Paths";
import {useNavigate} from "react-router-dom";

const ProfilePage = () => {
    const [user, setUser] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        getCustomer();
    }, []);
    const points = localStorage.getItem('points');

    async function getCustomer() {
        const resp = await getCustomersWallet();
        if (resp?.person) {
           setUser(resp?.person?.customer?.name);
        }
        console.log(resp);
    }

    return (
        <TelegramScreen
            className={`main-wrapper white-background`}  id='gesuredZone'>
            <div className='picklezone-container picklezone-container-dim picklezone-container-max'>
                    <div className={'card__tasks--title with-margin'}>
                        <p className={'text-bold-roboto'}>Profile</p>
                    </div>
                <div className='main-container'>
                    <img className={'profile-img'} src={ProfilePic} alt={'profile'}/>
                    <div className={'profile-text-container'}>
                        <div className={'profile-title'}>{points}</div>
                        <div className={'profile-text'}>DINKS</div>
                    </div>
                    <div className={'profile-form-container'}>
                    <form className='form-for-crypto-wallet' style={{top: '60%', position: 'absolute'}}>
                        <label></label>
                        <input
                            type="text"
                            className={`input-for-crypto-wallet profile`}
                            value={user}
                            readOnly
                            required
                        />
                    </form>
                    </div>
                </div>
                <div className='forms__bottom'>
                    
                    <div className={'picklezone-forms-wrapper'}>
                    <div className={'picklezone-forms-container'}>
                        <div className={'blue-text-name-roboto profile-link'}
                             onClick={() => navigate(PATH_Community)}>To Leaderboard</div>
                        </div>
                    <FooterFunction currentPage={'community'}/>
                    </div>
                </div>
            </div>
        </TelegramScreen>)


}

export default ProfilePage;
