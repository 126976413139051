import React, { useEffect, useState } from 'react';
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
import FooterFunction from "../../components/app/functions/FooterFunction";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTelegram } from "../../hooks/useTelegram";

import DefaultIcon from "../../assets/collectibles1.svg";
import BlueIcon from "../../assets/solar_circle-bottom-up-bold-duotone.svg";
import CancelledIcon from "../../assets/solar_chat-round-money-bold-duotone.svg";
import CloseIcon from "../../assets/close-circle-white.svg";
import {equipItem, getCollectibles, getEquipment, sellPaddle} from "../../logic/server/api";
interface Collectible {
    name: string;
    image: string,
    "asset": string,
    "type": string,
    "slot": string,
    "rarity": string,
    "item_id": string,
    "collection_id": string,
    "quantity": number
    "price": number
}

interface CollectiblesResponse {
    items: Collectible[];
    status: boolean;
}

const CollectiblesPage= React.memo(() => {
    const [collectibles, setCollectibles] = useState<Collectible[]>([]);
    const [equipment, setEquipment] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedPaddle, setSelectedPaddle] = useState<Collectible | null>(null);
    const [quantity, setQuantity] = useState<string>('1');
    const [isOpen, setIsOpen] = useState(false);
    const [maxLimit, setMaxLimit] = useState(0);
    const [initialHeight, setInitialHeight] = useState(window.innerHeight);
    const [keyboardOpen, setKeyboardOpen] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        setInitialHeight(window.innerHeight);
        getCollectiblesData();
        getEquip();
    }, []);

    // Detect viewport changes (keyboard open/close)
    useEffect(() => {
        const handleResize = () => {
            if (window.innerHeight < initialHeight - 100) {
                setKeyboardOpen(true);
            } else {
                setKeyboardOpen(false);
            }
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [initialHeight]);

    async  function getEquip() {
        const resp: any = await getEquipment();
        if (resp?.status && resp?.equipment?.paddle?.item_id) {
            setEquipment(resp.equipment.paddle.item_id);
        }
        setLoading(false);

    }
    async function getCollectiblesData() {
        const resp: any = await getCollectibles();
        if (resp?.status) {
            const itemsFilters = resp?.items?.sort((a: any, b: any) => b?.quantity - a?.quantity);
            setCollectibles(itemsFilters);
        }
    }

    async function onEquip(id: string) {
        const resp: any = await equipItem(id);
        if (resp?.status) {
            getEquip();
        }
    }

    function onSelectPaddle(item: Collectible) {
        if (item.quantity && (!equipment || equipment && (equipment !== item?.item_id))) {
            setLoading(true);
            onEquip(item.item_id);
        }
    }

    const handleChange = (evt: any) => {
        const newValue = !isNaN(evt?.target?.value) ? evt?.target?.value : 0;

        if (selectedPaddle && (newValue === '' || Number(newValue) >= 0 && Number(newValue) <= (maxLimit))) {
            setQuantity(newValue);
        }
        if (selectedPaddle && Number(newValue) >= 0 && Number(newValue) > (maxLimit)) {
            toast.error(`You don't have enough paddles to sell`);

        }
    };

    const onOpenModal = (item: Collectible) => {
        if (item.quantity >= 1) {
            const limit = equipment === item.item_id ? item.quantity - 1 : item.quantity;
            setMaxLimit(limit);
            setSelectedPaddle(item);
            setTimeout(() => {setIsOpen(true)}, 100);
        }
    }

    const handleIncrement = () => {
        if (selectedPaddle && quantity && (Number(quantity) + 1 <= (maxLimit))) {
            setQuantity((prevValue) => prevValue === '' ? '1' : String(Number(prevValue) + 1));        }
    };

    // Handle Decrement
    const handleDecrement = () => {
        if ((quantity !== '') && Number(quantity) - 1 > 0) {
            setQuantity((prevValue) => String(Number(prevValue) - 1));
        }
    };

    const handleBlur = () => {
        if (quantity === '') {
            setQuantity('1'); // Reset to 0 or some default if the value is empty
        }
    };

    const onCloseModal = () => {
        setQuantity('1');
        setIsOpen(false);
        setMaxLimit(0);
        setTimeout(() =>  setSelectedPaddle(null), 500);
    }


    async function sellRequest() {
        if (selectedPaddle && quantity && (Number(quantity) <= (maxLimit))) {
          const resp: any = await sellPaddle(selectedPaddle.item_id, Number(quantity));
          if (resp?.status) {
              setLoading(true);
              getCollectiblesData();
              toast.success(`You sold ${quantity} ${selectedPaddle.name} successfully!`);

              setTimeout(() => {
                  setQuantity('1');
                  setMaxLimit(0);
                  setIsOpen(false);
                  setSelectedPaddle(null);
                  setLoading(false);
              }, 300);
          }
        } if (selectedPaddle && quantity && (Number(quantity) > (maxLimit))) {
            // notification
            toast.error(`You don't have enough paddles to sell`);
        }
    }
    const onSellPaddle = () => {
        sellRequest();

    }

    return (
        <TelegramScreen className={`main-wrapper white-background`} id='gesuredZone'>
            {loading ? (<div className="loader-container">
                <div className="spinner"></div>
            </div>) : null}

            <div className='picklezone-container picklezone-container-dim'>
                <div className={'cards-container--top'}>
                    <div className={'card__tasks--title'}>
                        <p className={'text-bold-roboto'}>Inventory</p>
                    </div>
                    <div className={'cards__container cards__container--collectibles'}>
                        <div className={'container-text-for-collectibles'}>
                            <p className={'blue-roboto-14'}>Paddles</p>
                            <p className={'gray-roboto-14'}>4/{collectibles?.length} owned</p>
                        </div>
                        {collectibles.length > 0 ? (
                            collectibles.map((item: any) => (
                            <div  onClick={() => onSelectPaddle(item)}
                                className={
                             `picklezone-card__column-to-row  picklezone-setting-card
                             ${equipment && (equipment === item?.item_id) && (item?.quantity > 0) ? 'picklezone-setting-card--selected'
                                 : ''} ${item?.quantity === 0? 'picklezone-settings-card--disabled' : 'picklezone-settings-card--active'}`} key={item.id}>
                                <img src={item.image} alt="Default Icon"
                                     className={`picklezone-card--img-paddle ${item.quantity === 0 ? 'picklezone-card--img-paddle--grayscale' : ''}`}/>
                                 <p className={item.rarity === 'common' ? 'rarity-label rarity-common' : 'rarity-label rarity-rare'}>
                                     {item.rarity === 'common' ? 'Common' : 'Super Rare'}
                                 </p>

                            <div className={"wrapper-for-picklezone-card"}>
                                <div className={'id-of-selector'}>
                                   {item.quantity}
                                   </div>
                                        <p className={'picklezone-card__task--text'}>{item.name}</p>
                                        
                                        <div className={"row-text-for-collectibles"}>

                                        {equipment && (equipment !== item.item_id) && (<p className={'blue-roboto-12 gap-for-icons'}>
                                        <img src={BlueIcon} alt="Equip Icon" />
                                        {'Equip'}</p>)}

                                        {equipment && (equipment === item.item_id) && (
                                            <p className={'gray-roboto-12 gap-for-icons'} style={{opacity: 0.4}}>
                                        {'+Equipped'}</p>)}
                                        
                                        <p className={`blue-roboto-12 gap-for-icons 
                                        ${item.quantity < 1 || (item.item_id === equipment && item.quantity <= 1) ? 'disabled' : ''}`}
                                           onClick={(evt) =>
                                        {onOpenModal(item); evt.stopPropagation();}}>
                                        <img src={CancelledIcon} alt="Cancelled Icon"
                                             className={`${item.quantity < 1 || (item.item_id === equipment && item.quantity <= 1) ? 'modal-sell-img-dim' : ''}`}/>
                                        Sell</p>
                                        
                                         <p className={'blue-roboto-12 gap-for-icons'}>
                                       
                                        {item.price} DINKS</p>
                                    </div>
                                  </div>
                                </div>
                            ))
                        ) : (
                            <p>No collectibles available</p>
                        )}

                       
                    </div>
                </div>
                <div className='forms__bottom'>
                    <FooterFunction currentPage={'collectibles'} />
                </div>
            </div>


            {selectedPaddle && (
                <div className={`sell-modal ${isOpen ? 'open' : ''}`}>
                <div className={'sell-modal--header'}>
                    <p className={'sell-modal--header--text'}>Sell</p>
                    <div onClick={() => onCloseModal()}>
                        <img alt='close-icon' src={CloseIcon} className={'modal-close-icon'}/></div>
                </div>
                    <div className={`modal-main-container`}>
                        <div className={'picklezone-card--img-container'}>
                        <img alt={'paddle'} src={selectedPaddle.image} className={`modal-main-container--img  ${keyboardOpen ? 'modal-main-container--img-min' : ''}`}/>
                        <p className={selectedPaddle.rarity === 'common' ? 'rarity-common rarity-common--in-modal'
                            : 'rarity-rare rarity-rare--in-modal'}>
                            {selectedPaddle.rarity === 'common' ? 'Common' : 'Super Rare'}
                        </p>
                        </div>
                        <p  className={'modal-main-container--text'}>{selectedPaddle.name}</p>
                    </div>
                   <div className={'sell-modal--info'}>
                       <div className={'sell-modal--row'}>
                           <div className={'sell-modal--row-text'}><p>Price</p></div>
                           <div className={'sell-modal--row-price'}>{selectedPaddle.price} DINKS</div>
                       </div>
                       <div className={'sell-modal--row'}>
                           <div className={'sell-modal--row-text'}><p>Quantity</p>
                                   <p className={'sell-modal--row-text-mini'}>Max: {maxLimit}</p>
                               </div>
                           <div className="number-input-wrapper">
                               <button className="number-input--btn minus" onClick={handleDecrement}>−</button>
                               <input
                                   type="number"
                                   value={quantity}
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   max={maxLimit}
                                   className="modal-number-input"
                               />
                               <button className="number-input--btn plus" onClick={handleIncrement}>+</button>
                               {Number(quantity) === (maxLimit) && (
                                   <div className={'sell-modal--row-text-mini'} style={{marginLeft: '5px'}}>MAX</div>)}

                           </div>
                       </div>
                       <div className={'sell-modal--row'}>
                           <div className={'sell-modal--row-text'}><p>Total</p></div>
                           <div className={'sell-modal--row-price'}>{selectedPaddle.price * Number(quantity)} DINKS</div>
                       </div>
                   </div>
                    <div className={'sell-modal--button'}>
                        <button className={'btn-for-copy-links'}
                                onClick={() => onSellPaddle()}>Confirm</button>
                    </div>
                </div>)}


            <ToastContainer
                position="bottom-center"
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                autoClose={3000}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </TelegramScreen>
    );
});

export default CollectiblesPage;
